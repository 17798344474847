<template>
  <FormulateForm
    v-model="values"
    novalidate
    @submit="savePrice"
    #default="{isLoading}"
  >
    <div class="tw-px-4 tw-pb-4 tw-rounded tw-shadow-card tw-bg-gray-200 tw-mb-4">
      <div class="tw-flex tw-justify-between tw-gap-4 tw-items-center">
        <span class="tw-justify-start tw-inline-block tw-mt-4">
          <h3 class="tw-my-0 tw-font-bold">Geschatte waarde</h3>
        </span>
      </div>
      <div class="tw-my-2.5 tw-flex tw-justify-start tw-text-xs">
        Wat is de geschatte waarde van het pand waarop je aan het prospecteren bent indien de waarde nog niet is gekend?
      </div>
      <div class="tw-mb-4 tw-w-full tw-flex tw-flex-row tw-gap-2">
        <FormulateInput
          type="number"
          name="price"
          min="1"
          step="10000"
          :input-class="['tw-m-0']"
          outer-class="tw-m-0 tw-flex-grow"
          validation="bail|required|min:1"
        />
        <FormulateInput
          type="submit"
          title="Opslaan"
          :disabled="isLoading"
          outer-class="tw-m-0"
          :input-class="['tw-text-xs', 'tw-font-semibold']"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          <span class="tw-ml-1 tw-hidden sm:tw-inline-block">Opslaan</span>
        </FormulateInput>
      </div>

      <FormulateErrors/>
    </div>
  </FormulateForm>
</template>

<script>
import EventBus from '@/components/iam/bus'
import { updatePropertyFinancial } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

export default {
  name: 'PriceQuestion',
  props: {
    propertyId: {
      type: Number
    },
    price: {
      type: Number
    }
  },
  data () {
    return {
      values: {
        price: 0
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.price ? this.values.price = Number(this.price) : this.values.price = 0
    },
    async savePrice (data) {
      try {
        const responce = await updatePropertyFinancial(this.propertyId, data)
        successModal('De prijs werd opgeslagen.')
        EventBus.$emit('sales-reload-lead')
        return responce
      } catch (error) {
        errorModal('Er ging iets fout bij het opslaan van de prijs. Gelieve nogmaals te proberen.')
      }
    }
  }
}
</script>

<style scoped>

</style>
